@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
/* USED FONTS 
font-family: 'Poppins', sans-serif;
*/


:root{
  --red-color:#c61d23 ;
  --yellow-color:#ffde17 ;
  --dark-yellow-color: #ffa600;
  --green-color:#009444 ;  
  --black-color:#000000 ;
  --white-color:#ffffff ;
  --grey-color: #f2f3f6;
  --blue-grad: linear-gradient(45deg, var(--darkblue-color) 0%, var(--blue-color) 100%); 
  --ease: all 400ms ease-in-out;
}

body{
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

h1,h2,h3,h4{

}

.sec-head h1 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
}

.cen-head{
  text-align: center;
}

/* LP HEADER */
.top-btns {
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 15px;
}

.btn-style {
  text-decoration: none;
  border: 1px solid transparent;
  padding: 8px 35px;
  border-radius: 5px;
  font-weight: 500;
  transition: var(--ease);
  display: inline-block;
  font-size: 14px;
}

.lp-logo img {
  max-width: 190px;
}

.lp-header {
  padding: 10px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0 10px #0000004d;
  backdrop-filter: blur(5px);
}

.yellow-btn {
  background-color: var(--dark-yellow-color);
  border-color: var(--dark-yellow-color);
  color: var(--black-color);
}

.black-outline-btn {
  background-color: transparent;
  border-color: var(--black-color);
  color: var(--black-color);
}
.black-outline-btn:hover {
  background-color: var(--black-color);
  color: var(--white-color);
}
.yellow-btn:hover {
  background-color: var(--white-color);
  color: var(--black-color);
  border-color: var(--black-color);
}
/* END LP HEADER */

/* LP PAGE */
.vid-side .video-react {
  width: 100%;
  border: 5px solid var(--black-color);
  border-radius: 5px;
}

.vid-side video{
  object-fit: cover;
}

.banner-top {
  padding: 130px 0 70px;  
  background-image: url(assets/60251.jpg);  
  background-size: cover;  
  min-height: 100vh;
}

.banner-top h1 {
  font-size: 49px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.banner-top ul {
  margin: 0 0 20px;
  padding: 0 0 0 20px;
  list-style: none;
}

.banner-top ul li {
  margin-bottom: 7px;
  position: relative;
}

.banner-top .btns-area {
  margin: 0 0 20px;
}

.banner-top .btn-style {
  margin-right: 15px;
}

.banner-top .video-react .video-react-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border-color: var(--dark-yellow-color);
  color: var(--dark-yellow-color);
  font-size: 55px;
  line-height: 103px;
}

.banner-top .sub-txt {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  line-height: 30px;
  margin: 40px 0 0;
}

.banner-top ul li span {
  position: absolute;
  left: -20px;
  font-size: 14px;
  top: 1px;
  color: var(--dark-yellow-color);
}

/* END LP PAGE */

/* HOW IT WORKS */

.hiw-page {
  padding: 130px 0 70px;  
  background-size: cover;
  background-color: #ffefd6;
}

.sec-head h1 {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 700;
}

.step-icon {
  text-align: center;
}

.step-icon img {
  max-width: 120px;
}

.step-box {
  min-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-width: 2px;
  border-color: var(--red-color);
  border-style: dashed;
  padding: 40px 40px;
}

.sb-overlay {
  position: absolute;
  top: -12px;
  background-color: #ffefd6;
  width: 100%;
  height: calc(100% + 12px);
  z-index: 1;
  left: 40px;
  font-size: 14px;
  padding: 0 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.sb-content {
  position: relative;
  z-index: 2;
  background-color: #ffefd6;
}

.sb-content p {
  margin: 0;
}

.steps-area .row > div {
  padding: 0;
}

.r-box {
  border-width: 0 0 0 2px;
  padding: 0 0 0 0;
}

.r-box .sb-overlay {
  left: -10px;
  padding: 10px 0 0 0;
  height: auto;
  top: auto;
  position: relative;
  margin: 0;
}

.r-box .sb-content {
  left: -10px;
  background-color: #ffefd6;
  padding: 15px 40px 15px 0;
}

.rl-style {
  border-width: 2px 0 2px 2px;
}

.hiw-page .sec-head {
  max-width: 840px;
  margin: 0 auto 50px;
}

.hiw-page .sec-head {
  max-width: 840px;
  margin: 0 auto 50px;
}

.hiw-tabs button img {
  max-width: 80px;
  display: block;
  margin: 0 auto;
}

.hiw-tabs {
  justify-content: center;
  gap: 45px;
  border: none;
}

.hiw-tabs .nav-link span {
  background-color: var(--black-color);
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  display: block;
  margin: 10px 0 0;
  padding: 11px 60px;
  position: relative;
}

.hiw-tabs .nav-link.active {
  background-color: var(--red-color);
  border-color: var(--black-color);
}

.hiw-tabs .nav-link.active span:after {
  position: absolute;
  bottom: -10px;
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 15px 0 15px;
  border-color: var(--black-color) transparent transparent transparent;
  left: 50%;
  transform: translateX(-50%);
}

.hiw-tabs .nav-link {
  border: 1px solid var(--black-color);
  padding: 15px 0 0 0;
  border-radius: 5px;
  margin: 0 0 15px;
}

.steps-area .btn-style {
  margin: 50px auto 0;
  display: block;
  text-align: center;
  max-width: 230px;
}
/* END HOW IT WORKS */

/* REGISTER */
.page-margins {
  padding: 130px 0 70px;
}

.reg-page {
  /* background-color: var(--grey-color); */
  background-image: url(assets/60251.jpg);  
  background-size: cover;
}

.reg-page ul {
  margin: 0 0 20px;
  padding: 0 0 0 20px;
  list-style: none;
}

.reg-page ul li span {
  position: absolute;
  left: -20px;
  font-size: 14px;
  top: 1px;
  color: var(--dark-yellow-color);
}

.reg-page ul li {
  margin-bottom: 7px;
  position: relative;
  font-size: 21px;
  line-height: 27px;
}

.reg-page .content-side .sec-head span {
  display: block;
}

.yellow-txt {
  color: var(--dark-yellow-color);
}

.reg-page .content-side p,.reg-page .content-side strong {
  font-size: 28px;
  line-height: 35px;
  font-weight: 600;
  margin: 25px 0;
}

.form-side {
  background-color: var(--dark-yellow-color);
  border-radius: 5px;
  padding: 35px;
  border: 5px solid var(--red-color);
}

.form-side h3 {
  font-weight: 700;
  margin: 0 0 20px;
  text-align: center;
  border-bottom: 2px solid;
  padding-bottom: 16px;
}

.form-side .btn-style {
  display: block;
  text-align: center;
  margin: 30px 0 0;
}
/* REGISTER */

/* LOGIN */
.login-page {
  min-height: 100vh;
  background-image: url(assets/60251.jpg);
  background-size: cover;
  position: relative;
}

.login-form form {    
  background-color: #ffa6001a;
  padding: 40px;    
  border-radius: 5px;
  border: 4px solid var(--dark-yellow-color);
  backdrop-filter: blur(10px);
}

.login-form {
  position: absolute;
  width: 100%;
  left: 50%;
  max-width: 600px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login-form .sec-head {
  margin: 0 0 20px;
  text-align: center;
}

.top-btns .dropdown button {
  background: none !important;
color: var(--black-color);
border: none;
}

.top-btns .dropdown-toggle::after {
margin-left: 10px;
}
/* END LOGIN */


