/* DASHBOARD */
.form-check-input:checked {
    background-color: var(--dark-yellow-color) !important;
    border-color: var(--dark-yellow-color) !important;
}

.form-control,
.form-select {
    border-color: #999;
}

form-check-input:focus {
    border-color: var(--dark-yellow-color);
    box-shadow: 0 0 0 0.25rem rgb(255 166 0 / 33%) !important;
}

.form-control:focus {
    border-color: var(--dark-yellow-color) !important;
    box-shadow: 0 0 0 0.25rem rgb(255 166 0 / 33%) !important;
}

.dlogo {
    width: 100%;
    max-width: 180px;
}

.dheader {
    padding: 15px 0;
    box-shadow: 0 0 7px #0000006e;
    height: 91px;
}

.dheader .lft-side svg {
    margin-right: 4px;
}

.dheader .acc-drop {
    padding: 8px 10px;
}

.dheader .acc-drop>span {
    background-color: var(--dark-yellow-color);
    color: var(--black-color);
    width: 30px;
    display: inline-block;
    height: 30px;
    border-radius: 50%;
    line-height: 29px;
    margin-right: 5px;
}

.dheader .acc-drop {
    color: var(--black-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.dheader .acc-drop:hover>span {
    background-color: var(--black-color);
    color: var(--white-color);
}

.dheader .acc-drop:hover {
    color: var(--black-color);
}

.dheader .ryt-side {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: end;
}

.noti-drop svg {
    font-size: 30px;
    color: var(--dark-yellow-color);
}

.dheader .noti-drop {
    text-decoration: none;
    color: var(--black-color);
    position: relative;
}

.dheader .noti-drop>strong {
    background-color: var(--black-color);
    color: var(--white-color);
    font-weight: 400;
    font-size: 12px;
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    line-height: 20px;
    right: 0;
    top: 0;
}

.dheader .noti-drop:after {
    content: none;
}

.dbody {
    margin: 0 !important;
    background-image: url(../assets/60251.jpg);
    background-size: cover;
}

.sidebar-side {
    height: calc(100vh - 91px);
    background-color: #ececec;
    border-right: 1px solid #00000026;
}

.content-side {
    padding: 20px;
}

.dpage-heading h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
    background-color: #000;
    color: #fff;
    padding: 10px 15px;
    text-transform: uppercase;
}

.spro-box {
    border: 3px solid var(--dark-yellow-color);
    text-align: center;
    border-radius: 10px;
    padding: 0 20px 20px 20px;
    background-color: var(--white-color);
    transition: var(--ease);
}

.spro-box .icon-holder img {
    width: 60px;
}

.spro-box .icon-holder {
    background-color: var(--white-color);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: -52px auto 20px;
    line-height: 90px;
    border: 3px solid var(--dark-yellow-color);
    transition: var(--ease);
}

.spro-box h4 {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 10px;
}

.start-pro {
    margin: 80px 0 0;
}

.spro-box:hover .icon-holder {
    background-color: var(--dark-yellow-color);
    border-color: var(--white-color);
}

.spro-box:hover {
    background-color: var(--dark-yellow-color);
    border-color: var(--white-color);
}

.spro-box:hover .btn-style {
    background-color: var(--white-color);
}

.spro-box .btn-style:hover {
    background-color: var(--black-color);
    color: var(--white-color);
}

.main-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.main-nav a {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    text-decoration: none;
    color: var(--black-color);
    border-bottom: 1px dashed;
    margin: 0 -10px;
    transition: var(--ease);
}

.main-nav a:hover {
    background-color: var(--red-color);
    color: var(--white-color);
}

.main-nav a.active {
    background-color: var(--red-color);
    color: var(--white-color);
}

.top-checks ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 3px;
}

.top-checks {
    margin: 0 0 30px;
}

.top-checks a {
    background-color: var(--dark-yellow-color);
    text-decoration: none;
    color: var(--black-color);
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.top-checks li {
    width: 25%;
    text-align: center;
}

.top-checks a[disabled] {
    background-color: #ececec;
    pointer-events: none;
}

.authorslist {
    min-height: 150px;
    background-color: var(--white-color);
    border-radius: 5px;
    border: 1px solid #dee2e6;
}

.content-side {
    height: calc(100vh - 91px);
    overflow-y: auto;
}

.authorslist {
    padding: 15px;
}

.authorslist button {
    background-color: var(--red-color);
    color: var(--white-color);
    border: none;
    margin: 0 8px 0 0;
    font-size: 12px;
    border-radius: 3px;
}

.authorslist>div {
    margin-bottom: 5px;
}

.black-btn {
    background-color: var(--black-color);
    color: var(--white-color);
}

.black-btn:hover {
    border-color: var(--black-color);
    background-color: transparent;
    color: var(--black-color);
}

.setps-options {
    border-top: 1px solid #0000005c;
    padding-top: 20px;
    margin-top: 30px;
}

.setps-options .ryt-side {
    text-align: right;
}

.top-checks .step-done {
    background-color: #ececec;
}

.top-checks .step-done svg {
    color: var(--green-color);
}

.heading-with-note .btn-link,
.heading-with-note h4 {
    margin: 0;
}

.heading-with-note {
    display: flex;
    align-items: center;
}

.heading-with-note .btn-link {
    color: var(--black-color);
}

.heading-with-note {
    margin: 0 0 15px;
}

.heading-with-note .btn-link:hover {
    color: var(--dark-yellow-color);
}

.forms-area button svg {
    color: var(--black-color);
}

.badge-info {
    width: 80%;
    background-color: var(--dark-yellow-color);
    padding: 25px;
    border-radius: 5px;
    margin: 30px 0 0 30px;
    position: relative;
}

.badge-info:before {
    content: '';
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 15px 31px 15px 0;
    border-color: transparent var(--dark-yellow-color) transparent transparent;
}

.book-selection .nav-pills .nav-link.active {
    background-color: var(--red-color);
    color: var(--white-color);
}

.book-selection .nav-pills .nav-link {
    color: var(--black-color);
}

.narr-row {
    position: relative;
    border: 1px solid var(--red-color);
    border-radius: 5px;
    padding: 20px 20px;
    margin: 0 0 20px;
}

.narr-row .btn-danger {
    position: absolute;
    right: -49px;
    top: 0;
    width: 45px;
    height: 45px;
}

.narr-row .btn-danger svg {
    color: var(--white-color);
}

/* ACCOUNT */
.help a {
    display: inline-block;
    background-color: var(--white-color);
    color: var(--black-color);
    padding: 8px 15px;
    border-radius: 5px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    border: 1px solid #d6dae2;
    text-decoration: none;
    transition: var(--ease);
}

.help {
    text-align: right;
}

.help a:hover {
    background-color: var(--black-color);
    color: var(--white-color);
}

.acc-top p {
    margin: 20px 0 30px;
    font-size: 14px;
}

.acc-top h1 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.acc-pb-img {
    width: 130px;
    height: 130px;
    background-color: var(--black-color);
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 25px;
}

.acc-pb-img img {
    width: 100%;
    height: 100%;
}

.acc-profile-box {
    box-shadow: 3px 5px 6px 1px rgba(0, 0, 0, .22);
    background-color: var(--white-color);
    border-radius: 10px;
    padding: 30px 15px;
}

.acc-pb-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.acc-pb-list li {
    position: relative;
    margin: 0 0 10px;
    font-size: 13px;
    min-height: 17px;
    padding: 0 0 0 25px;
    font-weight: 500;
}

.acc-pb-list li svg {
    position: absolute;
    left: 0;
    top: 5px;
    color: var(--dark-yellow-color);
}

.acc-ob-edit button {
    width: 100%;
    margin: 10px 0 0;
    font-size: 14px;
}

.acc-information h4 {
    margin: 0 0 15px;
}

.change-pass {
    margin: 30px 0 0;
    font-size: 14px;
}

.change-pass h4 {
    margin: 0 0 20px;
}

.acc-information {
    margin: 0 0 33px;
    font-size: 14px;
}

.editprofile-modal .form-label {
    font-weight: 500;
    margin: 0 0 4px;
}

.editprofile-modal button svg {
    color: var(--black-color);
}

/* END ACCOUNT */

/* MY AUDIOBOOKS */
.draft-box {
    display: flex;
    background-color: var(--white-color);
    padding: 12px;
    gap: 10px;
    box-shadow: 3px 5px 6px 1px rgba(0, 0, 0, .22);
    border-radius: 10px;
    margin: 0 0 20px;
    transition: var(--ease);
}

.draft-box .img-holder img {
    width: 100%;
}

.draft-box .img-holder {
    min-width: 80px;
    max-width: 80px;
    padding: 10px;
    box-shadow: 0 0 4px #0000002e;
    border-radius: 5px;
    background-color: var(--white-color);
}

.draft-name {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-weight: 500;
}

.draft-name * {
    display: inline-block;
    text-decoration: none;
}

.draft-name a {
    color: var(--red-color);
    font-weight: 500;
}

.draft-name a svg {
    font-size: 11px;
    margin-left: 5px;
}

.draft-tabs .nav-link {
    color: var(--black-coloe);
}

.draft-tabs .nav-link.active {
    background-color: var(--dark-yellow-color);
    border-color: var(--dark-yellow-color);
}

.draft-box:hover {
    background-color: var(--dark-yellow-color);
}

.draft-box:hover a,
.draft-box:hover .draft-name {
    color: var(--white-color);
}

/* PUBLISH METADATA */
.words-count {
    display: block;
    font-size: 13px;
    margin: 3px 0 0;
    font-weight: 500;
}


.pub-genre {
    align-items: flex-end;
}

.genre-arrow {
    position: relative;
    top: -10px;
    text-align: center;
}

.req-star {
    color: var(--red-color);
}

.file-up {
    background-color: #ececec;
    padding: 15px;
    margin: 18px 0 15px;
}

.upload-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
}

.upload-icon svg {
    font-size: 40px;
    margin: 0 0 10px;
}

.upload-icon span {
    font-size: 13px;
    background-color: var(--dark-yellow-color);
    display: inline-block;
    padding: 5px 20px;
    border-radius: 5px;
    transition: var(--ease);
}

.upload-icon span:hover {
    background-color: var(--black-color);
    color: var(--white-color);
}

.file-up label {
    cursor: pointer;
    display: block;
    padding: 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 5px;
    height: 210px;
    width: 210px;
    margin: 0 auto 10px;
}

.file-infos {
    text-align: center;
}

.file-infos * {
    display: block;
}

.instruct strong {
    font-size: 16px;
    margin: 0 0 10px;
    display: block;
    font-weight: 600;
}

.instruct ul {
    padding: 0 0 0 15px;
}


.cover-details-link a {
    font-size: 16px;
    text-decoration: none;
    font-weight: 600;
    color: var(--red-color);
}

.audio-upload {
    padding: 15px;
    background-color: #ececec;
    border-radius: 10px;
}

.up-pdf label {
    font-weight: 600;
    font-size: 16px;
}

.distributorsList a {
    font-size: 13px;
    color: var(--green-color);
    font-weight: 500;
    text-decoration: none;
    display: inline-block;
}

.distributorsList a:after {
    content: '|';
    margin: 0 5px;
}

.distributorsList {
    margin: 0 0 15px;
}

.agree-checks {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}

.agreement-details {
    background-color: var(--white-color);
    padding: 25px;
    border-radius: 5px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 0 16px;
    border: 1px solid #ddd;
}

.agreement-details h4 {
    font-size: 18px;
    text-align: center;
    margin: 0 0 20px;
}

.agreement-details h5 {
    font-size: 16px;
}

.agreement-details table th {
    width: 50%;
}

.fileup-btn label {
    background-color: var(--dark-yellow-color);
    display: inline-block;
    padding: 8px 30px;
    border-radius: 5px;
    margin: 7px 0 0;
    cursor: pointer;
}

.fileup-btn {
    position: relative;
    overflow: hidden;
}

.fileup-btn input {
    visibility: hidden;
    position: absolute;
    left: -100%;
}

.track-note {
    background-color: #5f3f00;
    color: var(--white-color);
    padding: 20px;
    border-radius: 5px;
    border-radius: 5px;
    margin: 0 0 19px;
}

.track-note p {
    margin: 0;
}

.audio-upload h5 {
    border-bottom: 1px solid #cfcfcf;
    padding: 0 0 10px;
    margin: 0 0 15px;
}

.audio-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.audio-preview audio {
    width: 100%;
    margin-right: 15px;
}

.audio-preview button svg {
    color: var(--white-color);
}

.audio-box {
    border-bottom: 1px solid #cfcfcf;
    margin: 0 0 15px;
    
}

.subs-email {
    display: flex;
    align-items: center;
}

.subs-email button {
    color: var(--black-color);
    padding: 0 0;
    margin: -4px 0 0 10px;
}

.pob-tabs {
    justify-content: space-between;
}

.pob-tabs li {
    width: 33.333%;
}

.pob-tabs button {
    width: 100%;
    background-color: #ececec;
    color: var(--black-color);
    border-radius: 0 !important;
    font-weight: 500;
    padding: 10px 10px;
}

.pob-tabs button.active {
    background-color: #ffa600 !important;
    border-color: #ffa600 !important;
}

.threshold-value {
    font-size: 16px;
    font-weight: 600;
}

.threshold-value svg {
    color: var(--black-color);
}

.payout-pane .btn-style {
    margin: 0 0 30px;
}

.mini-note {
    color: var(--red-color);
    font-weight: 500;
}
.op-txt {
    flex-wrap: wrap;
}

.op-txt .optional-txt {
    width: 100%;
    font-size:12px;
}

/* TAX FORMS */
.tax-forms span {
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin: 8px 0 0;
}

.sigCanvas {
    border: 2px solid #000;
    border-radius: 5px;
    width: 100%;
    height: 200px;
  }

  .valid-points {
    margin: 20px 0 0;
}

.valid-points ul {
    padding: 0;
    list-style: none;
}

.valid-points li {
    position: relative;
    padding: 0 0 0 20px;
    margin: 0 0 6px;
}

.valid-points svg {
    position: absolute;
    left: 0;
    top: 2px;
}

.right-points svg {
    color: var(--green-color);
}

.neg-points svg {
    color: red;
}

.acc-pop .modal-dialog {
    max-width: 800px;
}

.top-pop-head strong {
    text-align: center;
    display: block;
}

.pop-three p {
    margin: 0 0 2px;
}

.pop-three {
    border-width: 1px 0px 1px 0px;
    border-color: #dee2e6;
    border-style: solid;
    padding: 10px 0;
    margin: 10px 0 15px;
}

.pop-three .row > div:nth-child(2) {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    text-align: center;
}

.pop-three strong {
    display: block;
}

.pop-three span {
    display: block;
}

.acc-pop span {
    font-size: 12px;
    line-height: 16px;
}

.acc-pop input {
    font-size: 14px;
}

.pop-other-field {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.pop-other-field > input {
    width: 100%;
}

.pop-other-field label {
    margin: 0;
}

.pop-other-field a {
    font-size: 12px;
}

.tax-classification label {
    font-size: 14px;
}

.tax-classification .form-check label {
    font-size: 13px;
    line-height: 15px;
}

.tax-classification .form-check {
    margin-bottom: 10px;
}

.part-heading {
    display: flex;
    /* align-items: center; */
    font-size: 16px;
    border: 1px solid var(--dark-yellow-color);
    margin: 10px 0 10px;
}

.part-heading span {
    font-size: 16px;
    width: 100%;
    padding: 5px 15px;
    text-align: center;
}

.part-heading strong {
    width: 130px;
    text-align: center;
    font-weight: 500;
    background-color: var(--dark-yellow-color);
    padding: 6px 5px;
}

.person-sig label {
    display: block;
    margin: 0 0 15px;
}

.person-sig button {
    font-size: 12px;
}

.acc-pop .modal-header{
    background-color: var(--dark-yellow-color);
}

.part-heading > * {
    align-content: center;
}

.alpha-list p {
    margin: 20px 0;
}

.olli-align{
    height: 0;
    overflow: hidden;
    display: block;
    margin: 0 0 -15px;
}
/* END TAX FORMS */

.contarea {
    background-color: var(--white-color);
    margin: 10px 0 0;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
}

.contarea input {
    margin-right: 5px;
}

.see-ins {
    display: block;
    color: #000;
    font-weight: 600;
    margin: 0 0 0 20px;
}